import { GraphQLClient } from 'graphql-request';

const endpointProd = 'https://platform.bombas.com/graphql';
const endpointStaging = 'https://platform.bombas.fyi/graphql';
const endpointPreprod = 'https://preproduction.platform.bombas.com/graphql';

export * from 'graphql-request';
export const client = (contentfulEnvironment: 'main' | 'staging' | 'preproduction' | string) =>
  new GraphQLClient(
    contentfulEnvironment === 'main'
      ? endpointProd
      : contentfulEnvironment === 'preproduction'
      ? endpointPreprod
      : endpointStaging,
    // All queries originating from Contentful only need US data
    { headers: { 'x-bom-country': 'US' } }
  );

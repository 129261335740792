import React, { useState, useEffect, CSSProperties } from 'react';
import { SidebarExtensionSDK } from '@contentful/app-sdk';
import { Stack, Button, Text } from '@contentful/f36-components';
import { client, gql } from '../services/graphql';
import { ExternalLinkIcon } from '@contentful/f36-icons';

interface ProductSidebarProps {
  sdk: SidebarExtensionSDK;
}

enum Disabled {
  Enabled = 0,
  Disabled = 1
}

interface ElementProps {
  href: string;
  tabIndex?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  style?: CSSProperties;
}

const ProductSidebar = ({ sdk }: ProductSidebarProps) => {
  const [isDisabled, setIsDisabled] = useState<Disabled>(Disabled.Disabled);
  const [entryId, setEntryId] = useState<string>(sdk.entry.getSys().id);
  const [shopifyUrl, setShopifyUrl] = useState<string>('');

  const shopifyStaging =
    'https://staging1-bombas.myshopify.com/admin/products/';
  const shopifyProd = 'https://bombas.myshopify.com/admin/products/';

  useEffect(() => {
    const unsubscribeChanges = sdk.entry.onSysChanged(() =>
      setEntryId(sdk.entry.getSys().id)
    );

    return () => {
      unsubscribeChanges();
    };
  }, [sdk.entry]);

  useEffect(() => {
    client(sdk.ids.environment)
      .request(
        gql`
          query getShopifyId($cmsId: ID!) {
            productByCmsId(id: $cmsId) {
              externalSystems {
                shopify
              }
            }
          }
        `,
        { cmsId: entryId }
      )
      .then((res) => {
        const shopifyId = res?.productByCmsId?.externalSystems?.shopify;
        if (shopifyId) {
          setShopifyUrl(
            `${
              ['main', 'preproduction'].includes(sdk.ids.environment) ? shopifyProd : shopifyStaging
            }${shopifyId}`
          );
          setIsDisabled(Disabled.Enabled);
        }
      });
  }, []);

  const buttonProperties: ElementProps = {
    href: isDisabled ? '#' : shopifyUrl
  };
  if (isDisabled) {
    buttonProperties.tabIndex = -1;
    buttonProperties.style = { pointerEvents: 'none' };
    buttonProperties.isDisabled = true;
  }

  return (
    <Stack flexDirection="column" spacing="spacingM">
      <Button
        as="a"
        target="_blank"
        rel="noopener"
        variant="secondary"
        isFullWidth
        startIcon={<ExternalLinkIcon />}
        {...buttonProperties}
      >
        Show in Shopify
      </Button>
      <Text fontColor="gray700">
        To create the Product in Shopify, publish the Page model for this
        Product.
      </Text>
    </Stack>
  );
};

export default ProductSidebar;
